import React from 'react'
import Us from '../components/screens/quienessomos/us/Us'
import Founders from '../components/screens/quienessomos/Founders'
import Colaborators from '../components/screens/quienessomos/Colaborators'
import Friends from '../components/screens/quienessomos/friends/Friends'

const QuienesSomos = () => {
    return (
        <>
            <Us />
            <Founders />
            <Colaborators />
            <Friends />
        </>
    )
}

export default QuienesSomos
