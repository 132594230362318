// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../assets/img/mapa-mundi.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".research {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") top center;\n  position: relative;\n  background-size: cover;\n}\n.research:before {\n  content: \"\";\n  background: rgba(255, 255, 255, 0.9);\n  position: absolute;\n  bottom: 0;\n  top: 0;\n  left: 0;\n  right: 0;\n}\n\n.research .container {\n  position: inherit;\n}", "",{"version":3,"sources":["webpack://src/styles/homescreen/research.styles.scss"],"names":[],"mappings":"AAAA;EACI,8DAAA;EACA,kBAAA;EACA,sBAAA;AACJ;AACI;EACI,WAAA;EACA,oCAAA;EACA,kBAAA;EACA,SAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;AACR;;AAGA;EACI,iBAAA;AAAJ","sourcesContent":[".research {\n    background: url(../../assets/img/mapa-mundi.png) top center;\n    position: relative;\n    background-size: cover;\n\n    &:before {\n        content: \"\";\n        background: rgba(255, 255, 255, 0.90);\n        position: absolute;\n        bottom: 0;\n        top: 0;\n        left: 0;\n        right: 0;\n    }\n}\n\n.research .container {\n    position: inherit;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
