import Friend from '../../../friend/Friend'
import SubHeading from '../../../subHeading/SubHeading'
import './friends.css'
import data_friend from '../../../../mocks/friends.json'

export default function Friends() {
  return (
    <section className='friends section__padding' id='friends'>
          <SubHeading 
            title='Amigas-Colaboradoras' 
            color={true}
          />

          <p className='p__main friends__text'>Es un tiempo histórico de gran fecundidad en relación con los proyectos y emprendimientos vinculados a la nueva Cultura Menstrual y el empoderamiento femenino. En este espacio presentamos a nuestras Amigas-colaboradoras dedicadas al cuidado y acompañamiento en ámbitos como el educativo, de la investigación, la salud, el activismo o de creación y distribución de materiales de gestión del flujo menstrual y el autocuidado, entre otros.</p>
          
          <div className='friends__items'>
            {data_friend.map((friend) => {
              return <Friend 
                        key={friend.id} 
                        name={friend.name}
                        region={friend.region}
                        email={friend.email}
                        project={friend.project}
                        web={friend.web}
                        facebook={friend.facebook}
                        instagram={friend.instagram}
                        x={friend.x}
                        imgUrl={friend.imgUrl}
                        linkedin={friend.linkedin}
                      />
            })}
          </div>
    </section>
  )
}