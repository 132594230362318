import SubHeading from '../../../subHeading/SubHeading'
import './research.css'

import menopausia from '../../../../assets/files/estudio.pdf'

export default function Research() {
  return (
    <section className='researchAlt section__padding'>
     <SubHeading title='Investigación' color={true} /> 
     <div className='researchAlt__article'>
        <h3 className='p__main'>Alteraciones del ciclo menstrual y confinamiento</h3>
        <article>
          <p className='p__main'>El confinamiento total que vivimos todas las personas durante el año 2020 lo hemos vivido de infinidad de maneras distintas. Pero hay peculiaridades concretas y comunes asociadas al proceso de la menstruación, que nos llevaron a sacar algunas conclusiones importantes.</p>  
          <p className='p__main'>La salud menstrual es un reto en los diversos contextos de crisis sanitaria, económica, climática y comunitaria, y por ende, es necesario estudiarla ante situaciones excepcionales de falta de condiciones para el buen funcionamiento de las hormonas sexuales femeninas.</p>
        </article> 
        <p className='p__main'>Puedes leer el artículo con los resultados aquí (página 13):</p>
        <a 
          className='main__button main__button--alt'
          href='https://matriz.net/mys4849/img/mys49-especial-covid.pdf'
          target="_blank"  
          rel='noreferrer'
        >Leer Artículo</a>
     </div>

     <div className='researchAlt__article'>
        <h3 className='p__main'>Atención sanitaria en menopausia y climaterio</h3>
        <article>
          <p className='p__main'>Esta Asociación ha respaldado un estudio sobre cómo perciben las mujeres en el Estado español, la atención sanitaria en la etapa de la menopausia y climaterio. Nace de la iniciativa de Carolina Ackermann, cofundadora de la Asociación de Cultura Menstrual, La Vida en Rojo...</p>  
          <p className='p__main'>Colabora Marisela Romero psicóloga y promotora de Igualdad de Género, cofundadora de la Asociación FemIgual, impulsora del objetivo final de este estudio, que es el de elaborar un modelo de carta que ampare y empodere a las mujeres a la hora de solicitar una correcta atención sanitaria.</p>
        </article> 
        <p className='p__main'>Puedes leer el artículo con los resultados aquí:</p>
        <a 
          className='main__button main__button--alt'
          href={menopausia} 
          target="_blank"  
          rel='noreferrer'
        >Leer Artículo</a>
     </div>

    </section>
  )
}