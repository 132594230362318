import SubHeading from '../../../subHeading/SubHeading'
import './red.css'

export default function Red() {
  return (
    <section className='red section__padding' id='red'>
      <SubHeading title='Tejer Red' color={true} />
      <div className='red__content'>
        <p className='p__main'>Vinculado a este quinto eje de intervención cuyo objetivo central es la articulación de una red de proyectos y personas relacionados con la Salud y la Cultura Menstrual es que te convocamos a formar parte de la red de Amigas y Colaboradoras de La Vida en Rojo desde nuevos aportes comunes en torno a la menarquia, la ciclicidad y el climaterio ¿Te unes?</p>
        <p className='p__main'>Escríbenos para poder informarte de las condiciones <span>(asociaciondeculturamenstrual@gmail.com)</span>.</p>
      </div>
    </section>
  )
}