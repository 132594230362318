import './article.css'

export default function Article({ imgUrl, title, url}) {

  return (
    <div className='press_articles-container_article'>
      <div className='press_articles-container_article-image'>
        <img 
          src= {require(`../../${imgUrl}`).default}
          alt={`Imagen del artículo: ${title}`} 
        />
      </div>

      <div className='press_articles-container_article-content'>
        <p>{title}</p>
        {(!url.startsWith("https") || !url.startsWith("http")) ? (
          <a 
          href={require(`../../${url}`).default}
          target='_blank'
          rel='noreferrer'
          >
            Leer Artículo completo
            <span className='arrow'>&rarr;</span>
          </a>
        ) : (
          <a  
          href={url}
          target='_blank'
          rel='noreferrer'
        >
          Leer Artículo completo 
          <span className='arrow'>&rarr;</span>
        </a>
        )}
      </div>
    </div>
  )
}