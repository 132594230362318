import './vallsencume.css'
import imgUrl from '../../../../assets/img/general/carme-valls-encume.png'

export default function VallsEncume() {
  return (
    <section className='vallsencume section__padding'>
      <div className='vallsencume__phrase'>
        <p className='p__main'>"La menstruación refleja la armonía de la diferencia y se manifiesta de forma equilibrada si el entorno es también armónico. De hecho, las condiciones de vida y trabajo influyen de modo directo en dicha armonía y en sus trastornos posteriores."</p>

        <p className='vallsencume__phrase--caption'>Dra. Carme Valls-Llobet ponente del 1er Encuentro de Cultura Menstrual (EnCuMe) Barcelona, 2019</p>
      </div>

      <div className='vallsencume__img'>
        <img src={imgUrl} alt='Conferencia de Carme Valls en el Encume 2019' />
      </div>
    </section>
  )
}