import './fanzine.css'

export default function Fanzine({ href, imgUrl }) {
  return (
    <div 
      className="press__fanzines-container__fanzine">
      <a 
        className="press__fanzines-container__fanzine-link"
        href={href}
        target='_blank' 
        rel='noreferrer'
      >
        <img 
          className="press__fanzines-container__fanzine-image" 
          src={require(`../../${imgUrl}`).default}
          alt='Cover from the fanzine number' 
        />
      </a>
    </div>
  )
}