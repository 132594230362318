import React from 'react'
import Header from '../components/screens/homescreen/header/Header'
import Purpose from '../components/screens/homescreen/purpose/Purpose'
import Activism from '../components/screens/homescreen/Activism'
import Press from '../components/screens/homescreen/press/Press'
import Education from '../components/screens/homescreen/Education'
import Red from '../components/screens/homescreen/red/Red'
import Research from '../components/screens/homescreen/research/Research'

const HomeScreen = () => {
    return (
        <> 
            <Header />
            <Purpose />
            <Activism /> 
            <Research />
            <Press />
            <Education />
            <Red />
        </>
    )
}

export default HomeScreen
