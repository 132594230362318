import './definition.css'
import imgUrl from '../../../../assets/img/general/definition_img.png'

export default function Definition() {
  return (
    <section className='definition'>
      <div className='definition__wrapper-img'>
        <img src={imgUrl} alt='Women in red carp event' />
      </div>

      <div className='definition__content'>
        <p className='p__main'>En el año 2021 nos constituimos como Asociación y tras el paréntesis del confinamiento, en el año 2022, convocamos la primera Tienda Roja como un espacio distendido y fértil para reconocernos como educadoras, terapeutas, divulgadoras, investigadoras y emprendedoras vinculadas a la nueva Cultura Menstrual.</p>
        <p className='p__main'>Más adelante apareció la figura de “Amiga Colaboradora” de la Asociación con las que mantenemos encuentros online “Cíclica-mente reunidas" y realizamos diferentes tipos de colaboraciones y de reuniones de trabajo tanto en el ámbito de la investigación, la innovación y la educación.</p>
        <p className='p__main mb-4'>Si quieres estar informada/informado de todas nuestras novedades y eventos, suscríbete para recibir nuestra newsletter.</p>
        <a 
          href='https://4bd89425.sibforms.com/serve/MUIFAMzQehmPCUlLQHdAJXCJ0Zxb4qQQHN6Xxj3-3v_Y0FbQOepZbh8gI7xM8gsYmkF__YkSrNsCQBXaZwnPuxh6unWMl2MMemeVmq7V72RV3iLvTPJn7Caz2w_S5z5Fck9b6M1Vst0rS5VpMOC_rhjqdmu7EPsfu1HVJaDCYt5sQA97jXJ3K7gXhqTjH8pgkXXGvMIlX1N88CS-' 
          className='main__button main__button--alt' 
          target='_blank' 
          rel='noreferRer'
        >Quiero suscribirme</a>
      </div>
    </section>
  )
}