import React from 'react'
import { Row, Col, Image, Container, Button } from 'react-bootstrap'

import useWindowSize from '../../../utilities/useWindowSize'

// Assets
import img_encume_sm from '../../../assets/img/encume-sm.png'
import img_encume_md from '../../../assets/img/general/encume-md.png'
import logo_encume from '../../../assets/img/logos/logo-blanco-global.png'

// Styles
import '../../../styles/encume/encume.styles.scss'

const Encume = () => {
    const { width }  = useWindowSize()

    return (
        <Row className='encume  d-flex flex-column-reverse flex-md-row bg-secondary px-0 mx-0 mb-5 p-md-5'>
            <Col 
                className='textbox d-flex align-self-center px-4 pt-3 pb-5 pl-md-0 pr-md-4 py-md-0 bg-secondary'>
                <Container className='p-0'>                   
                    <p className='normal-text text-white text-justify mb-4 px-0 m-0'>La Asociación de Cultura Menstrual, La Vida en Rojo nació de la voluntad de tejer red entre compañeras educadoras menstruales tras el I Encuentro nacional de Cultura Menstrual (EnCuMe) realizado en Barcelona en el año 2019.  Este encuentro, junto con los sucesivos online (2020 y 2021) recogieron la necesidad individual y colectiva de compartir investigación, lenguaje, vivencias, y debatir sobre las políticas públicas, sanitarias y educativas que sobre el ciclo menstrual se aplican en el Estado español. Estos encuentros sirvieron como espacio compartido para reflexionar sobre los diversos temas socioculturales y económicos que condicionan la vida de las mujeres y personas menstruantes</p>
                    <Button 
                        href='https://www.youtube.com/watch?v=lkb3WvWG1ss&t=6813s'
                        target='_blank'
                        className='main__button'>EnCuMe online 2021</Button>
                </Container>
            </Col>
            <Col 
                className='imgbox d-flex flex-column-reverse flex-md-column align-self-center p-0 pl-md-4'>
                    <Image 
                        className='encume_logo align-self-center mt-3 mt-md-0 mb-md-3'
                        alt='founder-1'
                        src={logo_encume}
                    />
                    { width > 767 ? (
                        <Image
                            src={img_encume_md}
                        />
                    ) : (
                            <Image
                                src={img_encume_sm}
                            />
                    )}
                    
            </Col>
        </Row>
    )
}

export default Encume
