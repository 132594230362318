import React from 'react'
import './header.css'

const Header = () => {

    return (
        <header className='header' id='header'>
            <h1>La vida en rojo
            </h1>
            <h2>Asociación de Cultura Menstrual</h2>
        </header>
    )
}

export default Header
