import React from 'react'

// Assets
import './offering.css'
// Styles
import SubHeading from '../../../subHeading/SubHeading'

const Offering = () => {

    return (
        <section className='offering section__padding'>
            <div className='offering__pdf'>
                <SubHeading title='Ofrecemos' color={true} />

                <p className='p__main'>Documento: "BIOLOGÍA MENSTRUAL, SISTEMA HORMONAL Y DIVERSIDAD SEXUAL" de la Dra. endocrinóloga Carme Valls-Llobet.</p>
            </div>

            <div className='offering__action'>
                <p className='p__main'>Por favor, envíanos un email a <span>asociacionculturamenstrual@gmail.com</span> solicitando el documento. Además, indícanos si trabajas en algún ámbito de la Cultura Menstrual, ya sea en salud, higiene, arte o educación, y también especifica tu lugar de residencia.</p>
            </div> 
        </section>
    )
}

export default Offering
