import React from 'react'

// Styles
import '../../../../components/screens/quienessomos/us/us.css'

// Assets
import urlImg from '../../../../assets/img/general/founders.png'
import SubHeading from '../../../subHeading/SubHeading'

const Us = () => {

    return (
        <section className='us section__padding'>
            <div className='us__content-founders'>
                <SubHeading title='Quiénes somos' color={false} />
                <p className='p__main'>Las fundadoras de la Asociación de Cultura Menstrual somos un círculo interdisciplinar de mujeres profesionales comprometidas con la cultura menstrual y convencidas de la importancia del trabajo colaborativo, asociativo e inspirado en principios feministas.</p>
            </div>
     
            <div className='us__wrapper-img'>
                <img src={urlImg} alt='Fotografía de las fundadoras.' />
            </div>

            <div className='us__content-process'>
                <p className='p__main'>Todas hemos tenido procesos personales en los que haber podido acceder a conocimientos y recursos sobre nuestra ciclicidad y salud menstrual fueron clave para la construcción de nuestro bienestar como mujeres. Por ello, nos nace la misión de sembrar un cambio de paradigma respecto a la salud femenina, menstrual y climatérica.</p>
            </div>

            <div className='us__content-projects'>
                <p className='p__main'>Además, nos une el hecho de que con nuestros proyectos profesionales abarcamos el acompañamiento y la formación en todos los ciclos vitales de la vida sexual y reproductiva de las mujeres; aportando desde la pequeña infancia hasta la madurez, herramientas y recursos para la construcción de una nueva Cultura menstrual integral, inclusiva, sostenible y feminista.</p>
            </div>
        </section>
    )
}

export default Us