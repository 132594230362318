import React from 'react'
import Encume from '../components/screens/encume/Encume'
import VallsEncume from '../components/screens/encume/vallsEncume/VallsEncume'
import Definition from '../components/screens/encume/definition/Definition'
import Offering from '../components/screens/encume/offer/Offering'

const CulturaMenstrualScreen = () => {
    return (
        <>
            <Encume /> 
            <Definition />
            <Offering />
            <VallsEncume />
        </>
    )
}

export default CulturaMenstrualScreen
