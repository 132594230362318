import './friend.css'
// Iconos
import { FaFacebook, FaDribbble, FaTwitter, FaLinkedin } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

export default function Friend({ name, region, email, project, web, facebook, instagram, x, imgUrl, linkedin }) {
 
  return (
    <div className='friend'>
      <img 
        src={require(`../../${imgUrl}`).default}
        alt='Logo de la amiga-colaboradora' 
      />

      <p className='friend__description'>
        {project}
      </p>
      <div>
        <p className='friend__name'>{name}</p>
        <p className='friend__location'>{region}</p>

        <div className='friend__rrss'>
          {web !== "" && (
            <a 
              href={web}
              target="_blank"
              rel='noreferrer'
            >
                <FaDribbble size={16} />
            </a>
          )}
          
          {facebook !== "" && (
            <a 
                href={facebook}
                target="_blank"
                rel='noreferrer'
            >
                <FaFacebook size={16} />
            </a>
          )}

          {instagram !== "" && (
            <a 
                href={instagram}
                target="_blank"
                rel='noreferrer'
            >
                <AiFillInstagram size={16} />
            </a>
          )}

          {x !== "" && (
            <a 
                href={x}
                target="_blank"
                rel='noreferrer'
            >
                <FaTwitter size={16} />
            </a>
          )}

          {linkedin !== "" && (
            <a 
              href={linkedin}
              target="_blank"
              rel='noreferrer'
            >
                <FaLinkedin size={16} />
            </a>
          )}
        </div>

        <p className='friend__email'>{email}</p>
      </div>
    </div>
  )
}
