import React from 'react'
import { Row, Col, Image, Container, Nav, Card } from 'react-bootstrap'

import img_nuria from '../../../assets/img/colaborators/nuria.png'
import img_eva from '../../../assets/img/colaborators/eva.png'

// Iconos
import { FaFacebook, FaInstagramSquare, FaDribbble } from "react-icons/fa";

// Styles
import '../../../styles/quienessomos/founders.styles.scss'
import SubHeading from '../../subHeading/SubHeading';

const Colaborators = () => {

    return (
        <Container fluid className='founders mt-5 pb-5'>
            <SubHeading title='Colaboradoras' color={true} />
            
            <Row className='m-0'>
                <Col data-aos="fade-left" 
                    lg={4} className='p-2'>
                    <div className='founder d-flex flex-column flex-sm-row flex-lg-column align-items-center align-items-sm-start px-3 py-5'>
                        <div md={3} className='d-flex justify-content-center px-0'>                           
                            <Image 
                                roundedCircle
                                className='founder_img'
                                alt='founder-1'
                                src={img_nuria}
                                width='100%'
                            />                        
                        </div>
                        <Card.Body md={9}  className='founder_info mt-4 px-0 pl-sm-4 pl-lg-0'>
                       
                            <h4>Nuria Beitia Hernández</h4>
                            {/* <span>Psicóloga</span>  */}
                            <p className='small-text'>Psicóloga (UB, 1996), Educadora en Salud Menstrual y Climatérica, Sexualidad y Crianza. Magistra en Estudios de las mujeres (Duoda-Universitad de Barcelona, 2004), posgraduada en Mediación y Resolución de conflictos (U. Ramon Llull 1998) y en “La Práctica de la relación (UB, 2004). Profesora al Máster de Igualdad Universidad País Vasco. Docente Universidad de Lleida (UdL).</p> 
                            <div className='social d-flex justify-content-center justify-content-sm-start mt-3'>
                               
                                <Nav.Link 
                                    href='https://www.instagram.com/nuriabeitia/' 
                                    target="_blank"
                                    className='p-0 mr-2'
                                >
                                    <FaInstagramSquare size={24} />
                                </Nav.Link>
                                <Nav.Link 
                                    href='https://nuriabeitia.net/' 
                                    target="_blank"
                                    className='p-0 mr-2'
                                >
                                    <FaDribbble size={24} />
                                </Nav.Link>
                            </div>
                        </Card.Body >
                    </div>
                </Col>
                
                <Col 
                    data-aos="fade-left" 
                    lg={4} className='p-2'>
                    <div className='founder d-flex flex-column flex-sm-row flex-lg-column align-items-center align-items-sm-start px-3 py-5'>
                        <div className='d-flex justify-content-center px-0'>
                            <Image 
                                roundedCircle
                                className='founder_img'
                                alt='founder-1'
                                src={img_eva}
                                width='100%'
                            />
                        </div>
                        <Card.Body className='founder_info mt-4 px-0 pl-sm-4 pl-lg-0'>
                            <h4>Eva Alba Invernot</h4>
                            {/* <span>Proyecto Hystera. Salud Femenina</span> */}
                            <p className='small-text'>Pedagoga y terapeuta corporal, especializada en el acompañamiento a mujeres y facilitadora de espacios de bienestar comunitario. Licenciada en Educación física, y con formación de Shiatsu, movimiento consciente y danza creativa y reflexología osteopática. Socia-fundadora de la cooperativa Brotes. Coordinadora del proyecto "Tejemos espacios de cuidados".</p>
                            <div className='social d-flex justify-content-center justify-content-sm-start mt-3'>
                                <Nav.Link 
                                    href='https://www.facebook.com/eva.shiatsu.reflexo/' 
                                    target="_blank"
                                    className='p-0 mr-2'
                                >
                                    <FaFacebook size={24} />
                                </Nav.Link>
                                <Nav.Link 
                                    href='https://www.instagram.com/eva.shiatsu.reflexo/' 
                                    target="_blank"
                                    className='p-0 mr-2'
                                >
                                    <FaInstagramSquare size={24} />
                                </Nav.Link>
                                <Nav.Link 
                                    href='https://www.evaalbabienestar.com/' 
                                    target="_blank"
                                    className='p-0 mr-2'
                                >
                                    <FaDribbble size={24} />
                                </Nav.Link>
                            </div>
                        </Card.Body>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Colaborators